import * as React from "react";
import { AuthClass } from "@aws-amplify/auth";
import { Profile, EventTypes, Auth0User, StudioSegment } from "../@types/data";
import { PlaySegmentType, UpdateSegmentType } from "../@types/functionTypes";
import { Voice } from "../@types/generated/types";
import { darkBgColours, colours, baseColours } from "../themes/colours";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { SegmentsByIdType } from "../components/studio/studioHooks";

export interface InterfaceProfileContext {
  profile: Profile;
  isAuthenticated: boolean;
  authChecked: boolean;
  token: string;
  signUpWithEmail: AuthClass["signUp"];
  loginWithEmail: AuthClass["signIn"];
  loginWithSocial: AuthClass["federatedSignIn"];
  logout: AuthClass["signOut"];
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ProfileContext = React.createContext<InterfaceProfileContext>({});

export interface InterfaceStudioContext {
  updateSegment: UpdateSegmentType;
  createNewSpeechOutputForSegment: (
    id: StudioSegment["id"],
    wafs: string,
    faders: number[]
  ) => Promise<void>;
  deleteSegmentFromTimeline: (id: string) => void;
  deleteEditFromTimeline: (id: string) => void;
  playSegment: PlaySegmentType;
  on: (event: EventTypes, f: Function) => void;
  translationLanguage: string;
  sourceLanguage: string;
  speakers: { value: string; label: string }[];
  voices: Voice[];
  videoElem: HTMLVideoElement | null | undefined;
  setVideoElem: (videoElem: HTMLVideoElement) => void;
  segmentsByIdRef: React.MutableRefObject<SegmentsByIdType>;
  scrollIdx: number;
  setScrollIdx: React.Dispatch<React.SetStateAction<number>>;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const StudioContext = React.createContext<InterfaceStudioContext>({});

export interface InterfaceTheme {
  bgColours: {
    [key in keyof typeof darkBgColours]: string;
  };
  colours: {
    [key in keyof typeof colours]: string;
  };
  baseColours: {
    [key in keyof typeof baseColours]: string;
  };
  isDark: boolean;
  toggle: () => void;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ThemeContext = React.createContext<InterfaceTheme>({});

export interface InterfaceAuth0Context
  extends Pick<
    Auth0Client,
    | "logout"
    | "getTokenWithPopup"
    | "getTokenSilently"
    | "loginWithRedirect"
    | "getIdTokenClaims"
    | "loginWithPopup"
    | "handleRedirectCallback"
  > {
  isAuthenticated: boolean | undefined;
  user: Auth0User;
  loading: boolean;
  popupOpen: boolean;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const Auth0Context = React.createContext<InterfaceAuth0Context>({});

export interface InterfaceSessionContext {
  lastHeartbeat: Date | undefined;
  heartbeatSuccess: boolean;
  totalTime: number | undefined;
  isIdle: boolean;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const SessionContext = React.createContext<InterfaceSessionContext>({});
