import * as React from "react";
import { useContext } from "react";
import { FlexRow } from "@papercup-ai/crane";
import { ProfileContext } from "../context";
import { useRouter } from "next/router";
import Router from "next/router";
import { omit } from "lodash";
import { LoadingPage } from "./LoadingPage";
import { useGA } from "../hooks/analytics/useGA";
import { Typography } from "@papercup-ai/crane";
import { Icon } from "@papercup-ai/crane";

export const REDIRECT_ON_LOGIN_STORAGE_KEY = "REDIRECT_ON_LOGIN_STORAGE_KEY";
const REDIRECT_ORIGIN = process.env.REDIRECT_ORIGIN;

export interface InterfacePage {
  accessLevel?: "publicOnly" | "public" | "private";
}

const PageBase: React.FunctionComponent<InterfacePage> = ({
  accessLevel = "private",
  children
}) => {
  useGA();
  const { isAuthenticated, authChecked, logout } = useContext(ProfileContext);

  if (typeof window === "undefined" || !authChecked) {
    return <LoadingPage />;
  }
  const router = useRouter();
  if (REDIRECT_ORIGIN) {
    const { pathname, search } = window.location;
    // replace history so back button won't return to page that redirects
    window.location.replace(REDIRECT_ORIGIN + pathname + search);
    return <Typography.H1>Redirecting...</Typography.H1>;
  }

  if (accessLevel === "publicOnly" && isAuthenticated) {
    const redirectOnLogin = JSON.parse(
      sessionStorage.getItem(REDIRECT_ON_LOGIN_STORAGE_KEY) as any
    );
    if (redirectOnLogin) {
      sessionStorage.removeItem(REDIRECT_ON_LOGIN_STORAGE_KEY);
      Router.push(redirectOnLogin);
    } else {
      Router.push({
        pathname: "/dashboard",
        query: {
          ...omit(router.query, ["code"]),
          page: "channels",
          section: "channels"
        } // Remove the code query param that is used by Cognito Auth, otherwise it kept requests OAuth
      });
    }
    return <LoadingPage />;
  }

  if (accessLevel === "private" && !isAuthenticated) {
    logout();
    const url = { pathname: router.pathname, query: router.query };
    sessionStorage.setItem(REDIRECT_ON_LOGIN_STORAGE_KEY, JSON.stringify(url));
    Router.push({
      pathname: "/login",
      query: router.query
    });
    return <LoadingPage />;
  }

  return (
    <>
      {window && !window.chrome && (
        <FlexRow
          background={"bg-brand"}
          dimensions={{ pad: "pa5" }}
          flex={{
            fai: "items-center",
            fjc: "justify-center"
          }}
        >
          <Icon name={"alert"} size={4} colour={"c-primary"} />
          <Typography.H3>
            It looks like you are using a browser which we do not support.
            Please use latest version of Chrome.
          </Typography.H3>
        </FlexRow>
      )}
      <FlexRow
        id="layout"
        background={"bg-primary"}
        dimensions={{ h: "vh-100", w: "vw-100" }}
        flex={{
          fai: "items-center",
          fjc: "justify-center"
        }}
      >
        {children}
      </FlexRow>
    </>
  );
};

export const Page = PageBase;
